const weekday = require('dayjs/plugin/weekday')
const localeData = require('dayjs/plugin/localeData')
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
const dayjs = require("dayjs")
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(quarterOfYear)
const XLSX = require("xlsx");
import axios from "axios"
import { envType, ossUrl } from '../config';
import Vue from "vue";
/* 格式化tree */
export const formatTree = (obj, options = { id, parentId, originId }) => {
    let copyedObj = JSON.parse(JSON.stringify(obj))  //深拷贝源数
    return copyedObj.filter(parent => {
        let findChildren = copyedObj.filter(child => {
            return parent[options['id']] === child[options['parentId']]
        })
        findChildren.length > 0 ? parent.children = findChildren : parent.children = []
        return parent[options['parentId']] == (options['originId'] === undefined ? 0 : options['originId'])  //返回顶层，依据实际情况判断这里的返回值
    })
}
/* 格式化tree */
export const formatTree_fix = (obj, options = { id, parentId, originIdList }) => {
    let copyedObj = JSON.parse(JSON.stringify(obj))  //深拷贝源数据
    return copyedObj.filter(parent => {
        let findChildren = copyedObj.filter(child => {
            return parent[options['id']] === child[options['parentId']]
        })
        findChildren.length > 0 ? parent.children = findChildren : parent.children = []
        return options['originIdList'].includes(parent[options['parentId']])
    })
}
/* 获取文件扩展名 */
export const getFileExtname = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if (lastIndexof) {
        const extname = fileName.substring(lastIndexof + 1)
        return extname.toLowerCase()
    }
    return ''
}

/* 获取文件图标地址 */
export const getFileIconAddr = (name) => {
    const extname = getFileExtname(name)
    let iconName = ""
    switch (extname) {
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
            iconName = "img.png"
            break
        case "pdf":
            iconName = "pdf.png"
            break
        case "docx":
        case "doc":
            iconName = "word.png"
            break
        case "ppt":
        case "pptx":
            iconName = "ppt.png"
            break
        case "xlsx":
        case "xls":
            iconName = "excel.png"
            break
        case "mp3":
            iconName = "mp3.png"
            break
        case "txt":
            iconName = "txt.png"
            break
        case "mp4":
            iconName = "mp4.png"
            break
        case "zip":
            iconName = "zip.png"
            break
        default:
            iconName = "unknown.png"
    }
    return require(`@/assets/image/fileicon/${iconName}`)
}
/* 预览文件 */
export const previewFile = (name, url, vm, index = 0, imgList = []) => {
    const extname = getFileExtname(name)
    if (extname == "ppt" || extname == "pptx" || extname == "xls" || extname == "xlsx" || extname == "doc" || extname == "docx" || extname == 'pdf') {
        vm.$FilePreview({
            url,
        })
    } else if (extname == "png" || extname == "jpg" || extname == "jpeg" == extname == "gif") {
        if (imgList.length == 0) {
            imgList = [url]
        }
        vm.$ImagePreview({
            index,
            imgList,
        })
    }
}
/* 获取文件扩展名包含点 */
export const getFileExtnameAll = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if (lastIndexof) {
        const extname = fileName.substring(lastIndexof)
        return extname.toLowerCase()
    }
    return ''
}
/* 获取文件名称 */
export const getFileName = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if (lastIndexof != -1) {
        return fileName.substring(0, lastIndexof)
    }
    return fileName
}

/* 数据导出ecxcel [[111, 222, 333], [444, 555, 666]] */
export const dataToExcel = (data, fileName) => {
    const ws_name = "SheetJS";
    const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, fileName);
}

/* table元素导出excel */
export const tableToExcel = (tableDom, fileName) => {
    const wb = XLSX.utils.table_to_book(tableDom, { sheet: "Sheet JS" });
    XLSX.writeFile(wb, fileName);
}

/* 数据统计计算百分比 */
export const getPercent = (a, b) => {
    if (a == 0) {
        return '0%'
    }
    if (b == 0) {
        return '--'
    }
    return (Math.abs(a - b) / b * 100).toFixed(2) + '%'
}
/* 数据统计处理数字3位加逗号 */
export const getThreeNum = (a) => {
    return Number(a).toLocaleString()
}
/* 数据统计获取增加类型 */
export const getIncreaseType = (a, b) => {
    return a - b > 0 ? 1 : -1
}

/* 下载文件 */
export const downloadFile = async (data, fileName) => {
    let url
    if (/^http/i.test(data)) {
        const blob = await getBlob(data)
        url = window.URL.createObjectURL(blob)
    } else if (data.includes("data:")) {
        url = data
    } else {
        url = window.URL.createObjectURL(data)
    }
    if (!fileName) {
        // 浏览器会自己添加后缀名
        fileName = new Date().getTime()
    }
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
}

/* 获取blob */
export const getBlob = (url) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response)
            }
        }
        xhr.onerror = (err) => {
            reject(err)
        }
        xhr.send()
    })
}

/* 手机号码中键4位数*处理 */
export const dealPhoneNum = (phoneNum) => {
    if (!phoneNum) {
        return ''
    }
    return phoneNum.replace(/(\d{3})\d+(\d{4})$/, "$1****$2")
}

/* 获取时长 */
export const getTimeTotal = (num, type = 1) => {
    if (num == '-') {
        return '--'
    }
    if (!num) {
        return '0分钟'
    }
    const h = parseInt(num / 60 / 60)
    const m = parseInt(num / 60 % 60)
    const s = num % 60
    if (type == 1) {
        return `${h ? h + '小时' : ''}${m ? m + '分钟' : ''}${s ? s + '秒' : ''}`
    } else if (type == 2) {
        return `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`
    }
}


/* 数字小数部分超过两位数就保存两位小数 */
export const numToFixed = (num) => {
    if (!num) {
        return 0
    }
    const str = String(num)
    const index = str.indexOf('.')
    if (index != -1 && str.substring(index).length > 2) {
        return num.toFixed(2)
    }
    return num
}

/* 数字限制 */
export const handleLimitNum = (prop, value, item, isInt) => {
    if (isInt) {
        value = value.replace(/[^\d^\.]+/g, "").replace(/^0+(\d)/, "$1");
    } else {
        value =
            value
                .replace(/。/g, ".")
                .replace(/[^\d^\.]+/g, "")
                .replace(/^0+(\d)/, "$1")
                .replace(/^\./, "0.")
                .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    }
    item[prop] = value
}
/* 计算数组中数字总和 */
export const arrToTotal = (arr) => {
    let sum = 0
    arr.forEach(item => {
        sum += item
    })
    return sum
}
/* 获取数组最大值 */
export const getArrMaxNum = (arr) => {
    if (!arr || arr.length == 0) {
        return 0
    }
    let max = arr[0]
    arr.forEach(n => {
        if (n > max) {
            max = n
        }
    })
    return max
}

// import { getCurWeekDP, getPrevWeekDP, getCurMonthDP, getCurThreeMonthDP } from '@/utils/tool'

/* 获取本周 */
export const getCurWeekDP = () => {
    const date = dayjs().weekday(0)
    if (dayjs().weekday() == 0) {
        const startDate = date.subtract(6, 'day').toDate()
        const endDate = date.toDate()
        return [startDate, endDate]
    } else {
        const startDate = date.add(1, 'day').toDate()
        const endDate = date.add(7, 'day').toDate()
        return [startDate, endDate]
    }

}
/* 获取上周 */
export const getPrevWeekDP = () => {
    const date = dayjs().weekday(0)
    if (dayjs().weekday() == 0) {
        const startDate = date.subtract(13, 'day').toDate()
        const endDate = date.subtract(7, 'day').toDate()
        return [startDate, endDate]
    } else {
        const startDate = date.subtract(6, 'day').toDate()
        const endDate = date.toDate()
        return [startDate, endDate]
    }
}
/* 获取本月 */
export const getCurMonthDP = () => {
    const monthDay = dayjs().daysInMonth()
    const startDate = dayjs().date(1).toDate()
    const endDate = dayjs().date(monthDay).toDate()
    return [startDate, endDate]
}
/* 获取上个月 */
export const getPrevMonthDP = () => {
    const monthDay = dayjs().subtract(1, 'month').daysInMonth()
    const startDate = dayjs().subtract(1, 'month').date(1).toDate()
    const endDate = dayjs().subtract(1, 'month').date(monthDay).toDate()
    return [startDate, endDate]
}
/* 本季度 */
export const getQuarterDP = () => {
    const quarter = dayjs().quarter()
    const monthDay = dayjs().month(quarter * 3 - 1).daysInMonth()
    const startDate = dayjs().month((quarter - 1) * 3).date(1).toDate()
    const endDate = dayjs().month(quarter * 3 - 1).date(monthDay).toDate()
    return [startDate, endDate]
}
/* 获取当前三个月 */
export const getCurThreeMonthDP = () => {
    const monthDay = dayjs().daysInMonth()
    const startDate = dayjs().subtract(2, 'month').date(1).toDate()
    const endDate = dayjs().date(monthDay).toDate()
    return [startDate, endDate]
}
/* 获取本年 */
export const getCurYearDP = () => {
    const startDate = dayjs().month(0).date(1).toDate()
    const endDate = dayjs().month(11).date(31).toDate()
    return [startDate, endDate]
}

/* 判断是否是移动端 */
export const isMobile = () => {
    const userAgentInfo = navigator.userAgent;
    let flag = false
    if (/(Android|iPhone|SymbianOS|Windows Phone|iPad|iPod)/i.test(userAgentInfo)) {
        flag = true
    }
    return flag
}
/* 手动拨打处理自定义字段 */
export const dealHandCallUserfield = (studentId) => {
    let userfield
    // VUE_APP_SERVER_ENV 1 内部项目 2 书法项目
    // dev_ 本地测试用  test_ 测试用  prod_ 正是用
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        if (
            process.env.VUE_APP_SERVER_ENV == "local" ||
            process.env.VUE_APP_SERVER_ENV == "dev"
        ) {
            userfield = `dev_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "test") {
            userfield = `test_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "prod") {
            userfield = `prod_${studentId}`;
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        if (
            process.env.VUE_APP_SERVER_ENV == "local" ||
            process.env.VUE_APP_SERVER_ENV == "dev"
        ) {
            // fs 或者 shufa 标识书法
            userfield = `shufatest_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "test") {
            userfield = `shufatest_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "prod") {
            userfield = `shufaprod_${studentId}`;
        }
    }
    return userfield
}
/* 保存任务时处理任务名 */
export const dealSaveCallTaskName = (name) => {
    let str = ""
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        switch (envType) {
            case "local":
            case "dev":
                str = "nd"
                break
            case "test":
                str = "nt"
                break
            case "prod":
                str = "np"
                break
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        switch (envType) {
            case "local":
            case "dev":
                str = "st"
                break
            case "test":
                str = "st"
                break
            case "prod":
                str = "sp"
                break
        }
    }
    return `${str}@@${name}`
}
/* 获取任务时处理任务名 */
/* 内部正式 np 内部测试 nt 内部dev nd   */
/* 书法正式 sp 书法测试 st */
export const dealShowCallTaskName = (name) => {
    if (!name) {
        return ""
    }
    return name.replace(/(np@@|nt@@|nd@@|sp@@|st@@)/g, "")
}
/* 获取任务环境类型 */
export const getCallTaskEnvType = (name) => {
    if (name.includes("np@@")) {
        return 'np'
    } else if (name.includes("nt@@")) {
        return 'nt'
    } else if (name.includes("nd@@")) {
        return "nd"
    } else if (name.includes("sp@@")) {
        return "sp"
    } else if (name.includes("st@@")) {
        return "st"
    } else {
        return ''
    }
}
/* 获取任务关键字前缀 */
export const getCallSearchNamePrefix = () => {
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        if (envType == 'dev' || envType == 'local') {
            return 'nd@@'
        } else if (envType == 'test') {
            return 'nt@@'
        } else if (envType == 'prod') {
            return 'np@@'
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        if (envType == 'dev' || envType == 'local') {
            return 'st@@'
        } else if (envType == 'test') {
            return 'st@@'
        } else if (envType == 'prod') {
            return 'sp@@'
        }
    }
}
/* 获取任务是否属于当前环境 */
export const checkCallTaskIsOpt = (callEnvType) => {
    let curCallEnvType
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        if (envType == 'dev' || envType == 'local') {
            curCallEnvType = 'nd'
        } else if (envType == 'test') {
            curCallEnvType = 'nt'
        } else if (envType == 'prod') {
            curCallEnvType = 'np'
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        if (envType == 'dev' || envType == 'local') {
            curCallEnvType = 'st'
        } else if (envType == 'test') {
            curCallEnvType = 'st'
        } else if (envType == 'prod') {
            curCallEnvType = 'sp'
        }
    }
    return callEnvType == curCallEnvType
}
/* 系统中的错误信息提交到服务器 */
const errDataList = []
export const apiErrorDataSendServe = (data) => {
    // 这种广告错误，不重复记录
    if (data.type == 'error-link' || data.type == 'error-script') {
        const { msg } = data
        for (let i = 0; i < errDataList.length; i++) {
            if (errDataList[i].msg == msg) {
                return
            }
        }
        errDataList.push(data)
    }
    const saveData = {
        ...data,
        userId: sessionStorage.getItem('userId'),
        username: sessionStorage.getItem('username'),
        name: sessionStorage.getItem('name'),
        curPage: location.href,
    }
    const xhr = new XMLHttpRequest();
    const url = "/v2/api/saveSysErrorData";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
            console.log(xhr.responseText);
        }
    };
    xhr.onerror = () => { }
    xhr.send(JSON.stringify(saveData));
}

/* 处理百分比，保留小数数 */
export const getPercentNum = (num, total, decimalsNum = 0) => {
    if (total == 0) {
        return '0.00%'
    }
    return (Math.round((num / total) * 100 * Math.pow(10, decimalsNum)) / Math.pow(10, decimalsNum)).toFixed(decimalsNum) + '%'
}
/* 版本对比v1本地版本v2远程版本 */
export const checkIsUpdate = (v1, v2) => {
    const listv1 = v1.split('.')
    const listv2 = v2.split(".")
    if (listv2[0] - listv1[0] > 0) {
        return true
    }
    if (listv2[1] - listv1[1] > 0) {
        return true
    }
    if (listv2[2] - listv1[2] > 0) {
        return true
    }
    return false
}
/* 检测是否需要更新 */
export const dealUpdate = async (vm) => {
    try {
        const { data: { version } } = await axios.get(`version.json?_t=${new Date().getTime()}`)
        if (checkIsUpdate(process.env.VUE_APP_VERSION, version) && !window.showUpdateTip) {
            window.showUpdateTip = true
            vm.$confirm("有新的版本是否更新? 如果正在打电话，请打完后再刷新页面重新加载！", "提示", {
                closeOnClickModal: false,
                confirmButtonText: "更新",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    location.reload()
                })
                .catch(() => { });
        }
    } catch (err) {
        console.error("checkIsUpdate err: ", err)
    }
}

/* 获取外显号码信息 通过json方式获取外显号码 */
export const apiGetYyyNumInfo = async () => {
    try {
        const { data: { yyyInfo } } = await axios.get(`version.json?_t=${new Date().getTime()}`)
        return yyyInfo
    } catch (err) {
        console.error("checkIsUpdate err: ", err)
    }
}

/* 获取拨打电话音频url */
/* 
    数企
    https://vip.bdsaas.com/bdsaas/phone/downloadRecordingBySessionId.do?sessionId=badu1831957992956411904
    优音云 
    https://ykf_record.uincall.com:4143/ykfRecord?url=%2Frecord%2F2024%2F08%2F14%2F6004800998%2F6004800998_7c1a7abe-eab1-4c7f-ccaf-1da588d877ed_18201511593_6004800998__20240814180030_dr.wav
    点控云 
    https://dkyrecord.obs.cn-north-4.myhuaweicloud.com:443/10037001002/20240714/20240714-174248-10037001002-18133097443-136xxxx6288-17746583914-cti4-1720950167.148065-98560.mp3
*/

export const apiGetCallAudioUrl = async (audioUrl, newFboccRecID) => {
    return new Promise((resolve, reject) => {
        if (newFboccRecID) {
            resolve(`${ossUrl}${newFboccRecID}`)
            return
        }
        // 李锐 和 宋昌
        if (!(sessionStorage.getItem("name") == "bwxx17" || sessionStorage.getItem("userId") == "560")) {
            resolve(audioUrl)
            return
        }
        Vue.prototype.msgInfo("音频获取中，请稍等！")
        let url = audioUrl
        if (/\.(mp3)$/.test(audioUrl)) {
            return resolve(url)
        }
        axios.get('/v2/api/downProxy', {
            params: {
                fileUrl: audioUrl
            }, responseType: 'blob', timeout: 0
        }).then(response => {
            const blob = new Blob([response.data], { type: 'audio/mpeg' });
            url = URL.createObjectURL(blob);
            resolve(url)
        }).catch(err => {
            console.log("apiGetCallAudioUrl err: ", err)
            resolve(url)
        })
    })

}

// 加载js文件
export const loadScript = (src) => {
    // 创建一个新的script标签
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src
    // 当脚本加载完成后执行回调
    script.onload = function () {
        console.log('Script loaded');
        // 脚本加载完成后可以在这里调用脚本中的函数
    };

    // 如果支持错误事件
    script.onerror = function () {
        console.error('Failed to load script');
    };

    // 将script标签插入到DOM中
    document.head.appendChild(script);
}

// 获取分钟数
export const getFloorMinute = (s) => {
    if (!s) {
        return 0
    }
    return Math.floor(s / 60)
}

// 随机生成字符串
export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        // 获取characters中的随机字符
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    return result;
}